import { db } from "../firebase"

function updateUserFCM(userID, token) {
  return db
    .collection("users")
    .doc(userID)
    .update({ fcmToken: token })
}

export default updateUserFCM

import React from "react"
import { Container, Row } from "react-bootstrap"
export default function BackgroundImage({ children, image }) {
  if (!image) {
    return children
  }
  return (
    <Row
      className="justify-content-center"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100%",
        width: "100%"
      }}
    >
      {children}
    </Row>
  )
}

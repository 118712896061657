import { navigate } from "gatsby"
import React from "react" // import react
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Badge from "@material-ui/core/Badge"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import AccountCircle from "@material-ui/icons/AccountCircle"
import NotificationsIcon from "@material-ui/icons/Notifications"
import MoreIcon from "@material-ui/icons/MoreVert"
import getUserData from "../../scripts/getUserData"
import logout from "../../scripts/logout"
import AnchorLink from "react-anchor-link-smooth-scroll"
import GridLoader from "react-spinners/GridLoader"
import Row from "react-bootstrap/Row"
import updateUserFCM from "../../scripts/updateUserFCM"
import { ListItemText, ListItemAvatar, Avatar } from "@material-ui/core"
import { auth, messaging, default as firebase } from "../../firebase"
import SignOutDialog from "../modals/SignOutDialog"
import { headerColor } from "../../constants"

class NewHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      loaded: false,
      userType: "guest",
      userID: "",
      initialized: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      notifMenu: null,
      screenWidth: 0,
      messages: [],
      joinedSlack: false,
      signingOut: false,
    }
    this.init = this.init.bind(this)
    this.signOut = this.signOut.bind(this)
    this.goToMyAccount = this.goToMyAccount.bind(this)
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.setMobileMoreAnchorEl = this.setMobileMoreAnchorEl.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.getClasses = this.getClasses.bind(this)
    this.getPermission = this.getPermission.bind(this)
    this.receiveMessage = this.receiveMessage.bind(this)
    this.getUnreadMessages = this.getUnreadMessages.bind(this)
    this.setNotifMenu = this.setNotifMenu.bind(this)
    this.readNotifs = this.readNotifs.bind(this)
  }

  readNotifs() {
    if (auth.currentUser !== null) {
      let messages = this.state.messages
      messages.map((value, index) => {
        messages[index].read = true
        return null
      })
      this.setState({ messages: messages })
    } else {
      //firebase null error
    }
  }

  getClasses() {
    return makeStyles(theme => ({
      grow: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },

      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
      sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
    }))
  }

  setNotifMenu(e) {
    this.setState({ notifMenu: e })
  }

  setAnchorEl(e) {
    this.setState({ anchorEl: e })
  }

  setMobileMoreAnchorEl(e) {
    this.setState({ mobileMoreAnchorEl: e })
  }

  getPermission() {
    if (firebase.messaging.isSupported()) {
      return firebase
        .messaging()
        .requestPermission()
        .then(() => {
          return firebase.messaging().getToken()
        })
        .then(token => {
          let user = auth.currentUser
          updateUserFCM(user.uid, token)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      return Promise.resolve("")
    }
  }

  getUnreadMessages() {
    let counter = 0
    this.state.messages.map((value, index) => {
      if (!value.read) {
        counter++
      }
      return null
    })
    return counter
  }

  receiveMessage() {
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage(payload => {
        let messages = this.state.messages
        let data = payload.notification
        data["read"] = payload.notification.read === "true" ? true : false
        messages.unshift([data])
        this.setState({
          messages: messages,
          read: this.state.read + 1,
        })
      })
    } else {
      // firebase null error
    }
  }

  init() {
    let user = auth.currentUser
    if (user === null || user === undefined) {
      this.setState({
        userType: "guest",
        authenticated: false,
        initialized: true,
      })
      return
    } else {
      this.setState({ userID: user.uid })

      if (!user.emailVerified) {
        this.setState({ userType: "notverified", initialized: true })
      } else {
        getUserData(user.uid)
          .then(data => {
            let usertype = data["userType"]

            this.setState({
              userType: usertype,
              initialized: true,
              joinedSlack: usertype === "student" && data.joinedSlack,
            })
            if (
              (data["fcmToken"] === undefined || !data["fcmToken"]) &&
              firebase.messaging.isSupported()
            ) {
              this.getPermission().then(() => {})
            } else {
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }

  goToMyAccount() {
    if (this.state.userType === "student") {
      if (!this.state.joinedSlack) {
        navigate("/JoinSlack/")
      } else {
        navigate("/student-dashboard/")
      }
    } else if (this.state.userType === "teacher") {
      navigate("/teacher-dashboard/")
    } else if (this.state.userType === "RM") {
      navigate("/student-dashboard/")
    }
  }

  signOut() {
    this.setState({ signingOut: true })
    setTimeout(() => {
      logout()
        .then(() => {
          this.setState({
            userType: "guest",
            authenticated: false,
            anchorEl: null,
            mobileMoreAnchorEl: null,
            signingOut: false,
          })
          navigate("/")
        })
        .catch(error => {
          // navigate("")
          console.log(error)
          //TODO ERROR HANDLE
        })
    }, 1500)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions)
    this.init()
    this.setState({ loaded: true })
    this.receiveMessage()
    this.updateWindowDimensions()
    auth.onAuthStateChanged(user => {
      this.init()
      this.receiveMessage()
    })
  }

  updateWindowDimensions() {
    if (this.state.screenWidth !== window.innerWidth) {
      this.setState({ screenWidth: window.innerWidth })
    }
  }

  globalNavigation(link, scroll) {
    this.setState({ mobileMoreAnchorEl: false })
    if (scroll) {
      if (window.location["pathname"] !== "/") {
        navigate(link)
      }
    } else {
      navigate(link)
    }
  }

  render() {
    const classes = this.getClasses()
    const isMenuOpen = Boolean(this.state.anchorEl)
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl)
    const isNotifMenuOpen = Boolean(this.state.notifMenu)
    const menuId = "primary-search-account-menu"
    const unreadMessages = this.getUnreadMessages()
    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={() => {
          this.setAnchorEl(null)
          this.setNotifMenu(null)
          this.setMobileMoreAnchorEl(null)
        }}
      >
        {this.state.userType !== "notverified" ? (
          <>
            {(this.state.userType === "student" && this.state.joinedSlack) ||
            this.state.userType === "teacher" ? (
              <>
                {this.state.userType === "student" && (
                  <MenuItem
                    onClick={() => this.globalNavigation("/ProfileStudent/")}
                  >
                    Profile
                  </MenuItem>
                )}
                <MenuItem onClick={() => this.goToMyAccount()}>Portal</MenuItem>
              </>
            ) : (
              <MenuItem onClick={() => this.globalNavigation("/JoinSlack/")}>
                Join Slack
              </MenuItem>
            )}
            <MenuItem onClick={() => this.signOut()}>Sign Out</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={() => this.globalNavigation("/VerifyEmail/")}>
              Verify Email
            </MenuItem>
            <MenuItem onClick={() => this.signOut()}>Sign Out</MenuItem>
          </>
        )}
      </Menu>
    )
    const notifMenu = (
      <Menu
        anchorEl={this.state.notifMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isNotifMenuOpen}
        onClose={() => {
          this.setAnchorEl(null)
          this.setNotifMenu(null)
          this.setMobileMoreAnchorEl(null)
        }}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: this.state.messages.length > 0 ? "40ch" : "20ch",
          },
        }}
      >
        {this.state.messages.length > 0 ? (
          this.state.messages.map((value, index) => {
            return (
              <MenuItem>
                {value.icon !== undefined && value.icon !== null && (
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={value.icon} />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={value.title}
                  secondary={value.body}
                  style={{ whiteSpace: "normal" }}
                />
              </MenuItem>
            )
          })
        ) : (
          <MenuItem>No Messages Yet</MenuItem>
        )}
      </Menu>
    )
    const mobileMenuId = "primary-search-account-menu-mobile"
    let renderMobileMenu = <></>
    let buttons = <></>
    if (
      this.state.userType === "notverified" ||
      (this.state.userType === "student" && !this.state.joinedSlack)
    ) {
      buttons = (
        <div className={classes.sectionDesktop}>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={event => this.setAnchorEl(event.currentTarget)}
            color="inherit"
            style={{ position: "absolute", top: "8px", right: "100px" }}
          >
            <AccountCircle />
          </IconButton>
        </div>
      )
      renderMobileMenu = (
        <Menu
          anchorEl={this.state.mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={() => this.setMobileMoreAnchorEl(null)}
        >
          <MenuItem onClick={event => this.setAnchorEl(event.currentTarget)}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Button style={{ color: "black" }}>Profile</Button>
          </MenuItem>
          <AnchorLink style={{ color: "black" }} href="#howitworks" offset="25">
            <MenuItem
              onClick={() => this.globalNavigation("/#howitworks", true)}
            >
              <Button color="inherit">How RemoteTA Works</Button>
            </MenuItem>
          </AnchorLink>
          <AnchorLink
            style={{ color: "black" }}
            href="#whyremoteta"
            offset="25"
          >
            <MenuItem
              onClick={() => this.globalNavigation("/#whyremoteta", true)}
            >
              <Button color="inherit">Why RemoteTA</Button>
            </MenuItem>
          </AnchorLink>
          <AnchorLink
            style={{ color: "black" }}
            href="#highlightedProjects"
            offset="25"
          >
            <MenuItem
              onClick={() =>
                this.globalNavigation("/#highlightedProjects", true)
              }
            >
              <Button color="inherit">Project Examples</Button>
            </MenuItem>
          </AnchorLink>

          <MenuItem onClick={() => this.globalNavigation("/ViewTasks/", false)}>
            <Button color="inherit" style={{ color: "black" }}>
              Volunteer Opportunities
            </Button>
          </MenuItem>
          <MenuItem onClick={() => this.globalNavigation("/Help/", false)}>
            <Button color="inherit" style={{ color: "black" }}>
              Help
            </Button>
          </MenuItem>
        </Menu>
      )
    } else if (this.state.userType !== "guest") {
      buttons = (
        <div className={classes.sectionDesktop}>
          {/* <IconButton
            color="inherit"
            edge="end"
            aria-label="notifications of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            style={{ position: "absolute", top: "8px", right: "150px" }}
            onClick={event => {
              this.setNotifMenu(event.currentTarget)
              if (event.currentTarget) {
                this.readNotifs()
              }
            }}
          >
            {unreadMessages > 0 ? (
              <Badge badgeContent={unreadMessages} color="secondary">
                <NotificationsIcon />
              </Badge>
            ) : (
              <NotificationsIcon />
            )}
          </IconButton> */}
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={event => this.setAnchorEl(event.currentTarget)}
            color="inherit"
            style={{ position: "absolute", top: "8px", right: "100px" }}
          >
            <AccountCircle />
          </IconButton>
        </div>
      )
      renderMobileMenu = (
        <Menu
          anchorEl={this.state.mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={() => {
            this.setMobileMoreAnchorEl(null)
            this.setAnchorEl(null)
            this.setNotifMenu(null)
          }}
        >
          {/* <MenuItem
            onClick={event => {
              this.setNotifMenu(event.currentTarget)
              if (event.currentTarget) {
                this.readNotifs()
              }
            }}
          >
            <IconButton
              color="inherit"
              aria-label="notifications of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
            >
              {unreadMessages > 0 ? (
                <Badge badgeContent={unreadMessages} color="secondary">
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>
            <Button style={{ color: "black" }}>Notifications</Button>
          </MenuItem> */}
          <MenuItem onClick={event => this.setAnchorEl(event.currentTarget)}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Button style={{ color: "black" }}>Profile</Button>
          </MenuItem>
          <AnchorLink style={{ color: "black" }} href="#howitworks" offset="25">
            <MenuItem
              onClick={() => this.globalNavigation("/#howitworks", true)}
            >
              <Button color="inherit">How RemoteTA Works</Button>
            </MenuItem>
          </AnchorLink>
          <AnchorLink
            style={{ color: "black" }}
            href="#whyremoteta"
            offset="25"
          >
            <MenuItem
              onClick={() => this.globalNavigation("/#whyremoteta", true)}
            >
              <Button color="inherit">Why RemoteTA</Button>
            </MenuItem>
          </AnchorLink>
          <AnchorLink
            style={{ color: "black" }}
            href="#highlightedProjects"
            offset="25"
          >
            <MenuItem
              onClick={() =>
                this.globalNavigation("/#highlightedProjects", true)
              }
            >
              <Button color="inherit">Project Examples</Button>
            </MenuItem>
          </AnchorLink>
          <MenuItem onClick={() => this.globalNavigation("/ViewTasks/", false)}>
            <Button color="inherit" style={{ color: "black" }}>
              Available Volunteer Opportunities
            </Button>
          </MenuItem>
          <MenuItem onClick={() => this.globalNavigation("/Help/", false)}>
            <Button color="inherit" style={{ color: "black" }}>
              Help
            </Button>
          </MenuItem>
        </Menu>
      )
    } else {
      buttons = (
        <div className={classes.sectionDesktop}>
          <Button
            color="inherit"
            onClick={() => this.globalNavigation("/Join/")}
            style={{ position: "absolute", right: "180px", top: "12px" }}
          >
            Join
          </Button>
          <Button
            color="inherit"
            onClick={() => this.globalNavigation("/Login/")}
            style={{ position: "absolute", right: "80px", top: "12px" }}
          >
            Login
          </Button>
        </div>
      )
      renderMobileMenu = (
        <Menu
          anchorEl={this.state.mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={() => this.setMobileMoreAnchorEl(null)}
        >
          <MenuItem onClick={() => this.globalNavigation("/Join/", false)}>
            <Button color="inherit">Join</Button>
          </MenuItem>
          <MenuItem onClick={() => this.globalNavigation("/Login/")}>
            <Button color="inherit">Login</Button>
          </MenuItem>
          <AnchorLink style={{ color: "black" }} href="#howitworks" offset="25">
            <MenuItem
              onClick={() => this.globalNavigation("/#howitworks", true)}
            >
              <Button color="inherit">How RemoteTA Works</Button>
            </MenuItem>
          </AnchorLink>
          <AnchorLink
            style={{ color: "black" }}
            href="#whyremoteta"
            offset="25"
          >
            <MenuItem
              onClick={() => this.globalNavigation("/#whyremoteta", true)}
            >
              <Button color="inherit">Why RemoteTA</Button>
            </MenuItem>
          </AnchorLink>
          <AnchorLink
            style={{ color: "black" }}
            href="#highlightedProjects"
            offset="25"
          >
            <MenuItem
              onClick={() =>
                this.globalNavigation("/#highlightedProjects", true)
              }
            >
              <Button color="inherit">Project Examples</Button>
            </MenuItem>
          </AnchorLink>

          <MenuItem onClick={() => this.globalNavigation("/ViewTasks/", false)}>
            <Button color="inherit" style={{ color: "black" }}>
              Volunteer Opportunities
            </Button>
          </MenuItem>
          <MenuItem onClick={() => this.globalNavigation("/Help/", false)}>
            <Button color="inherit" style={{ color: "black" }}>
              Help
            </Button>
          </MenuItem>
        </Menu>
      )
    }
    let links = (
      <>
        <AnchorLink style={{ color: "white" }} href="#howitworks" offset="50">
          <MenuItem
            color="inherit"
            onClick={() => this.globalNavigation("/#howitworks", true)}
            style={{ marginLeft: "50px" }}
          >
            How RemoteTA Works
          </MenuItem>
        </AnchorLink>
        <AnchorLink style={{ color: "white" }} href="#whyremoteta" offset="20">
          <MenuItem
            color="inherit"
            onClick={() => this.globalNavigation("/#whyremoteta", true)}
          >
            Why RemoteTA
          </MenuItem>
        </AnchorLink>
        <AnchorLink
          style={{ color: "white" }}
          href="#highlightedProjects"
          offset="20"
        >
          <MenuItem
            color="inherit"
            onClick={() => this.globalNavigation("/#highlightedProjects", true)}
          >
            Project Examples
          </MenuItem>
        </AnchorLink>
        <MenuItem
          color="inherit"
          onClick={() => this.globalNavigation("/ViewTasks/", false)}
          style={{ color: "white" }}
        >
          Available Volunteer Opportunities
        </MenuItem>
        <MenuItem
          color="inherit"
          onClick={() => this.globalNavigation("/Help/", false)}
          style={{ color: "white" }}
        >
          Help
        </MenuItem>
      </>
    )
    if (this.state.screenWidth < 1330) {
      buttons = (
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={event => this.setMobileMoreAnchorEl(event.currentTarget)}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div>
      )
      links = <></>
    }

    return (
      <div className={classes.grow}>
        <AppBar position="sticky" style={{ backgroundColor: headerColor }}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => this.globalNavigation("/")}
            >
              RemoteTA
            </Typography>
            <div className={classes.grow} />
            {links}
            {this.state.initialized ? (
              buttons
            ) : (
              <Row style={{ position: "absolute", right: "100px" }}>
                <GridLoader size={10} color={"#FFFFFF"} loading={true} />
              </Row>
            )}
          </Toolbar>
        </AppBar>
        <div>
          <SignOutDialog open={this.state.signingOut} />
        </div>
        {renderMobileMenu}
        {renderMenu}
        {notifMenu}
      </div>
    )
  }
}

export default NewHeader

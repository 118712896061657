import firebase from "firebase"

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)

if (firebase.messaging && firebase.messaging.isSupported()) {
  firebase
    .messaging()
    .usePublicVapidKey(process.env.GATSBY_FIREBASE_MESSAGING_PUBLIC_VAPID_KEY)
}

export let db = firebase.firestore()
export let auth = firebase.auth()
// export let messaging = firebase.messaging()
export let functions = firebase.functions()
export let analytics = firebase.analytics()
// export let storage = firebase.storage()
export default firebase

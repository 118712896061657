import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import CircularProgress from "@material-ui/core/CircularProgress"

export default function SignOutDialog(props) {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">
        {" "}
        Signing You Out... <CircularProgress />{" "}
      </DialogTitle>
    </Dialog>
  )
}

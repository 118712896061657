import { db } from "../firebase"

async function getUserData(userID) {
  let snapshot = await db
    .collection("users")
    .doc(userID)
    .get()

  return snapshot.data()
}

export default getUserData

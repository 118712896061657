/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { backgroundColor } from "../../constants"
import favicon from "../../images/favicon.ico"
import "../../styles/layout.css"
import NewHeader from "../headerAndFooter/newheader"
import BackgroundImage from "../utils/BackgroundImage"

class Layout extends React.Component {
  render() {
    var header = <NewHeader />
    if (this.props.header === false) {
      header = <div></div>
    }
    return (
      <>
        <Helmet>
          {/* <link rel="icon" href={favicon} /> */}
          <link
            rel="stylesheet"
            href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
            crossorigin="anonymous"
          ></link>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          <link
            type="text/css"
            rel="stylesheet"
            href="css/materialize.min.css"
            media="screen,projection"
          />
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
          />
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css"
            rel="stylesheet"
          />
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.8.10/css/mdb.min.css"
            rel="stylesheet"
          ></link>
          <link
            rel="stylesheet"
            href="https://unpkg.com/aos@next/dist/aos.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          />
        </Helmet>
        {header}
        <div
          style={{
            backgroundColor: backgroundColor,
            // height: "100vh",
            // width: "100vw",
            width: this.props.full ? "100vw" : "100%",
            height: this.props.full ? "100vh" : "100%",
          }}
        >
          <BackgroundImage image={this.props.image}>
            <div
              style={{
                margin: `0 auto`,
                padding: `0px {} 1.45rem`,
                maxWidth: "minContent",
                paddingTop: 0,
                overflowX: "hidden", //horizontal
              }}
            >
              <main>{this.props.children}</main>
            </div>
          </BackgroundImage>
        </div>
      </>
    )
  }
}

export default Layout
